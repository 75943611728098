import { Drawer, Form } from "antd";
import React, { useEffect, useState } from "react";
import { Select, Input, Button, Row, Col, notification, Upload } from "antd";

import { DrawerTitle, MainTitle, ActionButton, CloseBtnWrapper } from "Styles/global";
import { DrawerBodyPadding } from "./styles";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "Redux/App/Actions/Companies";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { userProfileData, companyName } from "Redux/App";
import UploadIcon from "Assets/Icons/UploadIcon";
import { CreateVideoTemplates, EditVideoTemplates } from "Redux/App/Actions/VideoTemplates";
import { SUPPORTED_VIDEO_RES } from "constants/Templates";
import { systemSettingsData } from "../../Redux/App/Reducers/System";

const UsersDrawer = (props) => {
  const [form] = Form.useForm();
  const userData = useSelector(userProfileData);
  const selectedCompany = useSelector(companyName);
  const [companies, setCompanies] = useState({
    data: [],
    loading: false
  });

  const [createTemplateLoading, setCreateTemplateLoading] = useState(false);
  const [file, setFile] = useState({
    selectedFile: null,
    fileAsText: ""
  });
  const dispatch = useDispatch();
  const fileSizeLimit = useSelector(systemSettingsData)?.promo_file_size;

  const [promotionOrientation, setPromotionOrientation] = useState(null);
  const [resolution, setResolution] = useState(null);

  useEffect(() => {
    dispatch(getCompanies(companies, setCompanies));
    if (props.selectedTemplate && props.drawerOpen.screen === "Edit Video") {
      form.setFieldsValue(props.selectedTemplate);
    }
  }, [props, form]);

  useEffect(() => {
    form.setFieldsValue({
      company: userData?.permission_level === "Fmtv" ? selectedCompany : userData?.company
    });
  }, [selectedCompany, userData]);

  const { Dragger } = Upload;

  const resetFields = () => {
    form.resetFields();
    setFile({
      selectedFile: null,
      fileAsText: ""
    });
    uploadProps.onRemove();
  };

  const onFinish = (values) => {
    values.orientation = promotionOrientation;
    values.resolution = resolution;

    if (file.selectedFile) {
      values.name = file.selectedFile.name;
      values.video = file.fileAsText.split("base64,")[1];
    }

    if (props.drawerOpen.screen === "Edit Video") {
      values.id = props.selectedTemplate.id;
      dispatch(EditVideoTemplates(values, props, setCreateTemplateLoading, resetFields));
    } else {
      dispatch(CreateVideoTemplates(values, props, setCreateTemplateLoading, resetFields));
    }
  };
  const [disableButton, setDisableButton] = useState();

  const uploadProps = {
    multiple: false,
    accept: ".mp4",
    fileList: file.selectedFileList,
    maxCount: 1,

    onChange: (info) => {
      const nextState = {};
      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFile = info.file;
          nextState.selectedFileList = [info.file];
          break;

        default:
          // error or removed
          nextState.selectedFile = null;
          nextState.selectedFileList = [];
      }
    },
    onRemove: () => {
      setFile({ ...file, selectedFile: null, selectedFileList: [] });
      form.setFieldValue({ file_url: null });

      return true;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload: (uploadedFile) => {
      if (uploadedFile.type !== "video/mp4") {
        notification.error({
          message: "Uploaded file format not supported"
        });
        return;
      }

      const fileSizeInMB = uploadedFile.size / 1024 ** 2;
      if (fileSizeInMB > fileSizeLimit) {
        notification.error({
          message: `Please upload file less than ${fileSizeLimit}MB.`
        });
        return fileSizeLimit;
      }

      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(uploadedFile);

      videoElement.addEventListener("loadedmetadata", () => {
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;

        const currentOrientation = videoWidth > videoHeight ? "landscape" : "portrait";
        setPromotionOrientation(currentOrientation);
        const currentResolution = `${videoWidth}x${videoHeight}`;
        setResolution(currentResolution);

        if (!SUPPORTED_VIDEO_RES.includes(currentResolution)) {
          setDisableButton(true);
          notification.error({
            message: `The promotion file is "${currentResolution}" and does not conform to one of the following resolutions. Please convert and re-upload.`,
            description: `1920x1080, 1280x720`
          });
        } else {
          setDisableButton(false);
        }
      });

      videoElement.load();

      // Read file as base64
      const reader = new FileReader();
      reader.onload = (e) => {
        setDisableButton(false);
        setFile({ ...file, fileAsText: e.target.result, selectedFile: uploadedFile });
      };
      reader.readAsDataURL(uploadedFile);

      return false;
    }
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Add new video template"
      placement={"right"}
      closable={false}
      onClose={() => props.setDrawerOpen(false)}
      open={props.drawerOpen.open}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => props.setDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{props.drawerOpen.screen}</p>
        </MainTitle>
      </DrawerTitle>
      <DrawerBodyPadding>
        <Form onFinish={onFinish} form={form} layout="vertical">
          {props.drawerOpen.screen === "Edit Video" && (
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
          )}

          <Form.Item name="tags" label="Tags">
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status"
              }
            ]}>
            <Select>
              <Select.Option value="Pending">Suspend</Select.Option>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Archive">Archive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="company" label="Company Name">
            <Select allowClear>
              {companies.data.map((company) => (
                <Select.Option key={company?.id} value={company?.id}>
                  {company.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {props.drawerOpen.screen === "Add New Template" ? (
            <Form.Item>
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <UploadIcon />
                </p>

                <p className="ant-upload-text">Click to upload the promotion files</p>
                <p className="ant-upload-hint">{`Maximum file size ${fileSizeLimit} MB`}</p>
              </Dragger>
            </Form.Item>
          ) : null}

          {createTemplateLoading && <LoadingSpinner />}

          <Row className="action-buttons">
            <Col span="12">
              <ActionButton background="##FFFFFF;" color="#9B9B9B" width="140">
                <Button onClick={() => props.setDrawerOpen(false)}>Cancel</Button>
              </ActionButton>
            </Col>
            <Col span="12">
              <ActionButton background="#39B54A" color="#FFFFFF" width="140">
                <Button htmlType="submit" disabled={disableButton}>
                  Submit
                </Button>
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default UsersDrawer;
