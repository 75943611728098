import { notification } from "antd";
import { requestStart, requestCompleted } from "../..";
import { doGet, doPost, doPut, doDelete } from "Requests/requests";

export function getInfotainmentList() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const data = await doGet("/infotainment");
      dispatch(requestCompleted());
      return data;
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error fetching Infotainment list"
      });
      throw error;
    }
  };
}

export function createInfotainment(data) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await doPost("/infotainment", data);
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error creating Infotainment"
      });
      throw error;
    }
  };
}

export function editInfotainment(data) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await doPut("/infotainment", data);
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error updating Infotainment"
      });
      throw error;
    }
  };
}

export function deleteInfotainmentBulk(ids) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await doDelete("/infotainment", { data: { ids } });
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error deleting Infotainment"
      });
      throw error;
    }
  };
}

export function refreshInfotainmentProcess() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await doPost("/infotainment/refresh", {});
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error refreshing Infotainment process"
      });
      throw error;
    }
  };
}
