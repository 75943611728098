import React, { useState, useEffect } from "react";
import { Button, Modal, notification, DatePicker, Table } from "antd";
import { ActionsDiv, ModalBtn } from "./styles";
import GrayTickIcon from "Assets/Icons/GrayTickIcon";
import MouseSquare from "Assets/Icons/MouseSquare";
import SmartHomeIcon from "Assets/Icons/SmartHomeIcon";
import SaveIcon from "Assets/Icons/SaveIcon";
import MessageIcon from "Assets/Icons/MessageIcon";
import CopyIcon from "Assets/Icons/CopyIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  CopyPromotion,
  PublishPromotion,
  DeactivatePromotion,
  ArchievePromotion,
  bulkEditPromotions
} from "Redux/App/Actions/Promos";
import { GetCampaigns } from "Redux/App/Actions/Campaigns";
import { data, userProfileData, isModalOpened, setTheModalClose, isErrorMessage } from "Redux/App";
import { systemSettingsData } from "Redux/App/Reducers/System";
import { GetSystemSettings } from "Redux/App/Actions/System";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { TableWrapper, ExtraContent } from "Styles/global";

const PromotionModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [confrimPopup, setConfirmPopup] = useState(false);
  const [archivePopup, setArchivePopup] = useState(false);
  const [publishPopup, setPublishPopup] = useState(false);
  const [bulkEditModal, setBulkEditModal] = useState({
    visible: false,
    type: "",
    date: null
  });
  const [inValidPromos, setInvalidPromos] = useState([]);
  const [inValidPromoNames, setInvalidPromoNames] = useState([]);

  const [bulkEditCampModalOpen, setBulkEditCampModalOpen] = useState(false);
  const [campaigns, setCampaigns] = useState({ data: [], loading: false });
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const userData = useSelector(userProfileData);
  const dispatch = useDispatch();
  const promotions = useSelector(data)?.filterData ?? [];
  const openModal = useSelector(isModalOpened);
  const showErrorMessage = useSelector(isErrorMessage);

  const splitMessage = showErrorMessage?.split(":");
  const beforeColon = splitMessage.length > 0 ? splitMessage[0]?.trim() : "";

  const dottedString = beforeColon.split(".");
  const bb = dottedString[dottedString.length - 1];
  const sss = dottedString.slice(0, dottedString.length - 1);
  const joinedString = sss.join(". ");

  const afterColon = splitMessage.length > 0 ? splitMessage[1]?.trim().substring(1) : "";
  const splitBullets = afterColon?.split("*") || [];
  const bullet1 = splitBullets.length > 0 ? splitBullets[0]?.trim() : "";
  const bullet2 = splitBullets[1]?.trim();
  const bullet3 = splitBullets[2]?.trim();
  const bullet4 = splitBullets[3]?.trim();
  const bullet5 = splitBullets[4]?.trim();

  const [bulkCampaignModalOpen, setBulkCampaignModalOpen] = useState(false);

  const systemSettings = useSelector(systemSettingsData);
  useEffect(() => {
    if (!systemSettings) {
      dispatch(GetSystemSettings());
    }
  }, [systemSettings, dispatch]);

  const isSingleSelect = systemSettings?.limit_promo_campaign ?? false;

  useEffect(() => {
    if (bulkEditCampModalOpen) {
      dispatch(GetCampaigns(campaigns, setCampaigns));
    }
  }, [bulkEditCampModalOpen]);

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const handleDispatchClosed = () => {
    dispatch(setTheModalClose());
    props.setSelectedRowKeys([]);
  };

  const disablePromotion = () => {
    const nonActiveOrUpcomingPromotion = [];
    const inValidPomosNames = [];
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status !== "Active" && promotion.status !== "Upcoming" && promotion.status !== "Paused") {
          inValidPomosNames.push(promotion.promotion_name);
          nonActiveOrUpcomingPromotion.push(promotion?.id);
        }
      }
    });
    setInvalidPromoNames(inValidPomosNames);
    setInvalidPromos(nonActiveOrUpcomingPromotion);
    setConfirmPopup(true);
  };
  const handleOkDeactivate = () => {
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));
    if (items.length > 0 && items[0] !== null && !items.includes(null)) {
      dispatch(DeactivatePromotion(items, handleCancel, props.setSelectedRowKeys));
    }
    setConfirmPopup(false);
  };

  const archievePromotion = () => {
    const nonActiveOrUpcomingPromotion = [];
    const inValidPomosNames = [];
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status === "Active" || promotion.status === "Upcoming") {
          inValidPomosNames.push(promotion.promotion_name);
          nonActiveOrUpcomingPromotion.push(promotion?.id);
        }
      }
    });
    setInvalidPromoNames(inValidPomosNames);
    setInvalidPromos(nonActiveOrUpcomingPromotion);
    setArchivePopup(true);
  };
  const handleOkArchive = () => {
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));
    if (items.length > 0 && !items.includes(null)) {
      dispatch(ArchievePromotion(items, setLoading, handleCancel, props.setSelectedRowKeys));
    }
    setConfirmPopup(false);
    setArchivePopup(false);
  };

  const HandlePublishPromotion = () => {
    const nonActiveOrUpcomingPromotion = [];
    const inValidPomosNames = [];
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status !== "Ready") {
          inValidPomosNames.push(promotion.promotion_name);
          nonActiveOrUpcomingPromotion.push(promotion?.id);
        }
      }
    });
    setInvalidPromoNames(inValidPomosNames);
    setInvalidPromos(nonActiveOrUpcomingPromotion);
    setPublishPopup(true);
  };
  const handleOkPublic = async () => {
    setPublishLoading(true);
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));
    if (items.length > 0 && items[0] !== null && !items.includes(null)) {
      await dispatch(PublishPromotion(items, handleCancel, props.setSelectedRowKeys, props.refreshPromotions));
    }
    setPublishPopup(false);
    setPublishLoading(false);
  };

  const handleBulkEditCampaign = () => {
    setBulkEditCampModalOpen(true);
    setSelectedCampaigns([]);
  };

  const rowSelectionCampaign = {
    type: isSingleSelect ? "radio" : "checkbox",
    selectedRowKeys: selectedCampaigns,
    onChange: (newSelected) => setSelectedCampaigns(newSelected),
    getCheckboxProps: (record) => ({
      disabled: record.status === "Archived" || record.status === "Pending"
    })
  };

  const handleBulkCampaignOk = () => {
    dispatch(
      bulkEditPromotions({
        promotions: props.selectedRowKeys,
        campaignId: selectedCampaigns
      })
    );
    setBulkEditCampModalOpen(false);
    props.setSelectedRowKeys([]);
  };

  const handleBulkCampaignCancel = () => {
    setBulkEditCampModalOpen(false);
    setSelectedCampaigns([]);
  };

  const columnsCampaign = [
    { title: "Campaign", dataIndex: "campaign_name" },
    { title: "Screen Type", dataIndex: "screen_type" },
    { title: "Status", dataIndex: "status" }
  ];

  const handleBulkEditStartDate = () => {
    setBulkEditModal({ visible: true, type: "start", date: null });
  };

  const handleBulkEditEndDate = () => {
    setBulkEditModal({ visible: true, type: "end", date: null });
  };

  const handleBulkEditDateOk = () => {
    dispatch(
      bulkEditPromotions({
        promotions: props.selectedRowKeys,
        date: bulkEditModal.date,
        type: bulkEditModal.type
      })
    );
    setBulkEditModal({ ...bulkEditModal, visible: false });
    props.setSelectedRowKeys([]);
  };

  const handleBulkEditDateCancel = () => {
    setBulkEditModal({ visible: false, type: "", date: null });
  };

  return (
    <>
      <ModalBtn onClick={showModal}>
        <MouseSquare />
      </ModalBtn>

      <Modal title="Confirm" open={publishPopup} onOk={handleOkPublic} onCancel={() => setPublishPopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are not in a Ready status and can not be Published:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li key={promotion}>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Promotions should be published.
            <ul>
              {promotions
                .filter((promotion) => props?.selectedRowKeys.includes(promotion?.id))
                .map((promotion) => (
                  <li key={promotion.id}>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>

      <Modal title="Confirm" open={archivePopup} onOk={handleOkArchive} onCancel={() => setArchivePopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are in an Active or Upcoming status and can not be Archived until they are
            Deactivated:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li key={promotion}>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Promotions should be Archived.
            <ul>
              {promotions
                .filter((promotion) => props?.selectedRowKeys.includes(promotion?.id))
                .map((promotion) => (
                  <li key={promotion.id}>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>

      <Modal title="Confirm" open={confrimPopup} onOk={handleOkDeactivate} onCancel={() => setConfirmPopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are not in an Active , Upcoming or Paused status and can not be Deactivated:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li key={promotion}>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Active or Upcoming Promotions should be Deactivated.
            <ul>
              {promotions
                .filter((promotion) => props?.selectedRowKeys.includes(promotion?.id))
                .map((promotion) => (
                  <li key={promotion.id}>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>

      <Modal
        title="View Promotion"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        width={450}>
        {userData?.permission_level !== "Creator" && (
          <>
            <ActionsDiv onClick={HandlePublishPromotion}>
              <div className="action">
                <SmartHomeIcon />
                <span className="actions-title"> Publish</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
            <ActionsDiv onClick={disablePromotion}>
              <div className="action">
                <MessageIcon />
                <span className="actions-title">Deactivate</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          </>
        )}

        {userData?.permission_level !== "Publisher" && (
          <>
            <ActionsDiv
              onClick={() => {
                dispatch(
                  CopyPromotion(props?.selectedRowKeys, {
                    setLoading,
                    handleCancel,
                    setSelectedRowKeys: props.setSelectedRowKeys
                  })
                );
              }}>
              <div className="action">
                <CopyIcon />
                <span className="actions-title"> Copy</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>

            <ActionsDiv onClick={archievePromotion}>
              <div className="action">
                <SaveIcon />
                <span className="actions-title">Archive</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          </>
        )}

        {props?.selectedRowKeys?.length > 1 && (
          <ActionsDiv onClick={handleBulkEditCampaign}>
            <div className="action">
              <MessageIcon />
              <span className="actions-title">Bulk Edit Campaign</span>
            </div>
            <GrayTickIcon />
          </ActionsDiv>
        )}
        {props?.selectedRowKeys?.length > 1 && (
          <>
            <ActionsDiv onClick={handleBulkEditStartDate}>
              <div className="action">
                <MessageIcon />
                <span className="actions-title">Bulk Edit Start Date</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
            <ActionsDiv onClick={handleBulkEditEndDate}>
              <div className="action">
                <MessageIcon />
                <span className="actions-title">Bulk Edit End Date</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          </>
        )}
        {(loading || publishLoading) && <LoadingSpinner />}
      </Modal>

      <Modal
        title={
          bulkEditModal.type === "start"
            ? "Select New Start Date"
            : bulkEditModal.type === "end"
              ? "Select New End Date"
              : ""
        }
        open={bulkEditModal.visible}
        onCancel={handleBulkEditDateCancel}
        footer={null}>
        <DatePicker style={{ width: "100%" }} onChange={(val) => setBulkEditModal({ ...bulkEditModal, date: val })} />
        <p style={{ marginTop: 20 }}>
          {bulkEditModal.type === "start"
            ? `Promotions with an end date before the new start date will be skipped. Also, any promotions that are Active or Upcoming will be Deactivated and will require Publishing again.`
            : bulkEditModal.type === "end"
              ? `Promotions with a start date after the new end date will be skipped. Also, any promotions that are Active or Upcoming will be Deactivated and will require Publishing again.`
              : ""}
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, gap: 10 }}>
          <Button onClick={handleBulkEditDateCancel}>Cancel</Button>
          <Button type="primary" onClick={handleBulkEditDateOk} disabled={!bulkEditModal.date}>
            OK
          </Button>
        </div>
      </Modal>
      {/* <Modal
        open={openModal}
        closable={false}
        footer={[
          <Button
            style={{
              background: '#39B54A',
              color: 'white',
              borderTop: '1px solid white',
            }}
            key="ok"
            onClick={handleDispatchClosed}
          >
            OK101
          </Button>,
        ]}
      >
        <p className="mb-0">
          {joinedString}
          <br />
          {bb ? bb : null}
          <ul>
            {bullet1 ? <li>{bullet1} </li> : null}
            {bullet2 ? <li>{bullet2} </li> : null}
            {bullet3 ? <li>{bullet3} </li> : null}
            {bullet4 ? <li>{bullet4} </li> : null}
            {bullet5 ? <li>{bullet5} </li> : null}
          </ul>
        </p>
      </Modal> */}

      <Modal
        title="Bulk Edit Campaign"
        open={bulkEditCampModalOpen}
        onCancel={() => setBulkEditCampModalOpen(false)}
        footer={null}
        width={700}>
        <ExtraContent>Select the Campaign(s) associated with these promotions</ExtraContent>
        <TableWrapper>
          <div className="table-height">
            <Table
              columns={columnsCampaign}
              dataSource={campaigns.data?.filter((c) => c.status !== "Archived" && c.status !== "Pending")}
              pagination={false}
              loading={campaigns.loading}
              rowSelection={rowSelectionCampaign}
            />
          </div>
        </TableWrapper>
        <p style={{ marginTop: 16 }}>
          Promotions with an orientation or aspect ratio that does not conform with the campaign will be skipped. Also,
          any promotions that are Active or Upcoming will be Deactivated and will require Publishing again.
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, gap: 10 }}>
          <Button onClick={handleBulkCampaignCancel}>Cancel</Button>
          <Button type="primary" onClick={handleBulkCampaignOk} disabled={selectedCampaigns.length < 1}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PromotionModal;
