import React, { Fragment, useEffect, useState, useCallback } from "react";
import { TemplateWrapper } from "./styles";
import { ActionButton } from "Styles/global";
import { Input, Form, Button, Radio, Checkbox, InputNumber, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading, userProfileData } from "Redux/App";
import { systemSettingsData as stateData } from "Redux/App/Reducers/System";
import { GetSystemSettings, EditSystemSettings } from "Redux/App/Actions/System";
import { getLatestWorkflowForEachProcess } from "Requests/scheduler";
import moment from "moment";
import { isHexCode } from "utils/isHexCode";
import { getFonts } from "Requests/fonts/index";
import debounce from "lodash/debounce";

function System() {
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const email = useSelector(userProfileData)?.email;
  const userTimezone = useSelector(userProfileData)?.time_zone ?? "UTC";
  const loading = useSelector(stateLoading);
  const [form] = Form.useForm();
  const [latestWorkflow, setLatestWorkflow] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [fontLoading, setFontLoading] = useState(false);
  const [fontFamily, setFontFamily] = useState([]);

  useEffect(() => {
    const getWorkflows = async () => {
      try {
        const data = await getLatestWorkflowForEachProcess();
        setLatestWorkflow(data);
      } catch (e) {
        console.log(e);
      }
    };
    getWorkflows();
  }, []);

  const fetchFonts = async (searchTerm = "", offset = 0, limit = 100) => {
    try {
      setFontLoading(true);
      const response = await getFonts({ search_term: searchTerm, offset, limit });
      setFonts(response);
    } catch (error) {
      console.error("Failed to fetch fonts", error);
    } finally {
      setFontLoading(false);
    }
  };

  const debouncedFetchFonts = useCallback(
    debounce((searchTerm) => {
      fetchFonts(searchTerm);
    }, 300),
    []
  );

  useEffect(() => {
    fetchFonts();
  }, []);

  const handleFontSearch = (value) => {
    debouncedFetchFonts(value);
  };

  useEffect(() => {
    if (data) {
      const fontFamilyData = data.font_family ? data.font_family.split(",") : [];
      setFontFamily(fontFamilyData);

      form.setFieldsValue({
        ...data,
        logging: Boolean(data?.logging ?? 0),
        limit_promo_campaign: Boolean(data?.limit_promo_campaign ?? 0),
        scheduler_flag: Boolean(data?.scheduler_flag ?? 0),
        font_family: fontFamilyData
      });

      if (fontFamilyData.length > 0) {
        getFonts({ post_script_names: fontFamilyData.join(",") })
          .then((response) => {
            setFonts((prevFonts) => {
              const fontMap = new Map();
              [...response, ...prevFonts].forEach((font) => {
                fontMap.set(font.post_script_name, font);
              });
              return Array.from(fontMap.values());
            });
          })
          .catch((error) => {
            console.error("Failed to fetch selected fonts", error);
          });
      }
    }
  }, [data, form]);

  useEffect(() => {
    dispatch(GetSystemSettings(email));
  }, [dispatch, email]);

  const fontOptions = fonts.map((font) => ({
    value: font.post_script_name,
    label: font.full_name
  }));

  const optionsExpiredRetentionPeriod = [
    { label: "1 day", value: "1" },
    { label: "30 days", value: "30" },
    { label: "60 days", value: "60" },
    { label: "90 days", value: "90" }
  ];

  const optionsArchivedRetentionPeriod = [
    { label: "1 day", value: "1" },
    { label: "30 days", value: "30" },
    { label: "60 days", value: "60" },
    { label: "90 days", value: "90" }
  ];

  const optionsForcedLogoutPeriod = [
    { label: "5 min", value: "5" },
    { label: "15 min", value: "15" },
    { label: "30 min", value: "30" },
    { label: "60 min", value: "60" }
  ];

  const optionsProcessFrequency = [
    { label: "1 hr", value: "1" },
    { label: "2 hr", value: "2" },
    { label: "6 hr", value: "6" },
    { label: "12 hr", value: "12" },
    { label: "24 hr", value: "24" }
  ];

  const onFinish = (values) => {
    let fontFamilyValue;
    if (Array.isArray(values.font_family)) {
      fontFamilyValue = values.font_family.join(",");
    } else if (typeof values.font_family === "string") {
      fontFamilyValue = values.font_family;
    } else {
      fontFamilyValue = null;
    }

    dispatch(
      EditSystemSettings({
        ...values,
        id: data.id,
        font_family: fontFamilyValue
      })
    );
  };

  return (
    <Fragment>
      <TemplateWrapper>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="FMTV support email address" name="fmtv_support_email_address" required>
            <Input required />
          </Form.Item>
          <Form.Item label="Recipient email address" name="recipient_email_address" required>
            <Input required type="email" />
          </Form.Item>
          <Form.Item label="Sender email address" name="sender_email_address" required>
            <Input required type="email" />
          </Form.Item>
          <Form.Item label="Sender email password" name="sender_email_password" required>
            <Input required type="password" />
          </Form.Item>

          <Form.Item label="SMTP Username" name="smtp_username" required>
            <Input required />
          </Form.Item>
          <Form.Item label="SMTP Password" name="smtp_password" required>
            <Input required type="password" />
          </Form.Item>
          <Form.Item label="AWS SES SMTP Server" name="aws_ses_smtp_server" required>
            <Input required />
          </Form.Item>
          <Form.Item label="AWS SES SMTP Port" name="aws_ses_smtp_port" required>
            <InputNumber required min={1} />
          </Form.Item>

          <Form.Item label="Clear cache URL" name="cache_clear_url">
            <Input maxLength={null} />
          </Form.Item>
          <Form.Item label="Select expired rentention period" name="expired_retention_period">
            <Radio.Group>
              {optionsExpiredRetentionPeriod.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Select archived retention period" name="archived_retention_period">
            <Radio.Group>
              {optionsArchivedRetentionPeriod.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Select forced logout period" name="forced_logout_period">
            <Radio.Group>
              {optionsForcedLogoutPeriod.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Select promotion status process frequency" name="promotion_status_frequency">
            <Radio.Group>
              {optionsProcessFrequency.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Do you want to allow verbose logging?" name="logging" valuePropName="checked">
            <Checkbox style={{ fontSize: "24px" }}>Allow</Checkbox>
          </Form.Item>
          <Form.Item label="Do you want to enable scheduler running?" name="scheduler_flag" valuePropName="checked">
            <Checkbox style={{ fontSize: "24px" }}>Run</Checkbox>
          </Form.Item>
          <div
            style={{
              marginTop: "-20px",
              marginBottom: "15px"
            }}>
            {latestWorkflow.map((it) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center"
                  }}
                  key={it.process_name}>
                  <p>{`${it.process_name} - ${
                    it?.run_timestamp ? moment.utc(it.run_timestamp).tz(userTimezone).format("YYYY-MM-DD HH:mm") : "N/A"
                  } ${it?.status ? `(${it.status})` : ""}`}</p>
                </div>
              );
            })}
          </div>
          <Form.Item label="Limit Promotion to one campaign?" name="limit_promo_campaign" valuePropName="checked">
            <Checkbox style={{ fontSize: "24px" }}>Yes</Checkbox>
          </Form.Item>
          <Form.Item
            label="Promotion File Size Limit (in MB)"
            name="promo_file_size"
            rules={[
              {
                message: "Incorrect file size",
                validator: (_, value) => {
                  if (/^[0-9]+$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("File size can only be in numbers");
                  }
                }
              }
            ]}>
            <InputNumber placeholder={"Add size in MBs"} min={1} />
          </Form.Item>
          <Form.Item
            label="Default Color Palette"
            name="color_palette"
            rules={[
              {
                validator(_, value) {
                  if (!value) return Promise.reject("Color Palette is required");
                  const errors = [];
                  const hex = value.split(",");
                  if (hex.length > 8) errors.push("Cannot contain more than 8 colors");
                  for (const color of hex) {
                    if (!isHexCode(color)) errors.push(`${color} is an invalid hex color`);
                  }
                  if (errors.length > 0) return Promise.reject(errors);
                  return Promise.resolve();
                }
              }
            ]}>
            <Input placeholder={"Add comma separated hex codes"} min={1} max={8} />
          </Form.Item>
          <Form.Item label="Default Font Family" name="font_family">
            <Select
              mode="single"
              allowClear
              placeholder="Select default font families"
              showSearch
              onSearch={handleFontSearch}
              filterOption={false}
              loading={fontLoading}
              options={fontOptions}
            />
          </Form.Item>
          <Form.Item label="Current Version of FuelMediaTV Backend" name="backend_version">
            <Input readOnly />
          </Form.Item>
          <div className="actions-btn">
            <ActionButton background="#39B54A" color="#FFFFFF" width="160">
              <Button htmlType="submit" loading={loading}>
                Save
              </Button>
            </ActionButton>
          </div>
        </Form>
      </TemplateWrapper>
    </Fragment>
  );
}

export default System;
