import React, { useRef, useState, useEffect } from "react";
import { FilterFilled, SwapOutlined } from "@ant-design/icons";
import { Button, Input, Table, Row, Col, Tooltip, Drawer, Spin, notification } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TableWrapper } from "Styles/global";
import { CampaignsTable } from "./styles";
import { ReviseSequence, GetPromoSequence } from "Redux/App/Actions/Campaigns";
import { GetCampaigns, SortCampaign } from "Redux/App/Actions/Campaigns";
import { requestCompleted } from "Redux/App";
import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";
import DrawerComponent from "Components/Shared/Drawer";
import CampaignDrawer from "../Promotions/CampaignDetail/CampaignDetail";
import AdminCreateCampaign from "./AdminCampaign";
import { userProfileData, companyName, selectedCompaign, setCompany } from "Redux/App";

const Campaigns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(userProfileData);
  const selectedCompany = useSelector(companyName);
  const selected = useSelector(selectedCompaign);
  const [drawerOpen, setDrawerOpen] = useState({ CampaignDetailDrawer: false, editCampaignName: "" });
  const [createCampaignDrawer, setCreateCampaignDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [campaigns, setCampaigns] = useState({});
  const [campaignsData, setCampaignsData] = useState({ loading: false, data: [] });
  const [sequenceDrawerOpen, setSequenceDrawerOpen] = useState(false);
  const [sequenceData, setSequenceData] = useState({ data: null, loading: false });

  useEffect(() => {
    dispatch(setCompany(selected));
    if (selectedCompany) {
      dispatch(GetCampaigns(campaignsData, setCampaignsData));
    }
  }, [selectedCompany]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Row gutter={8}>
          <Col span="14">
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />
          </Col>
          <Col span="5">
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
          </Col>
          <Col span="5">
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              type="primary"
              style={{ marginLeft: "5px", background: "#fff", color: "#9B9B9B", border: "1px solid #d9d9d9" }}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    render: (text) => (searchedColumn === dataIndex ? (text ? text.toString() : "") : text)
  });

  const handleSequenceClick = (item) => {
    setSequenceData({ data: null, loading: false });
    dispatch(GetPromoSequence(sequenceData, setSequenceData, item.id));
    setSequenceDrawerOpen(true);
  };

  const handleCancelSequence = () => {
    setSequenceDrawerOpen(false);
  };

  const handleSubmitSequence = () => {
    if (!sequenceData.data) return;
    const { layout_id, playlist_id, widgets, company, campaign } = sequenceData.data;
    const updatedWidgets = widgets.map((w) => ({
      ...w,
      displayOrder: w.displayOrder 
    }));
    const dataToSend = {
      playlist_id,
      layout_id,
      company,
      campaign,
      widgets: updatedWidgets
    };

    dispatch(
      ReviseSequence(dataToSend, () => {
        setSequenceDrawerOpen(false);
      })
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination || !sequenceData.data) return;
    const { widgets } = sequenceData.data;
    const reordered = reorder(widgets, result.source.index, result.destination.index).map((x, i) => ({
      ...x,
      displayOrder: i + 1
    }));
    setSequenceData((prev) => ({ ...prev, data: { ...prev.data, widgets: reordered } }));
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status"
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("campaign_name"),
      render: (text, item) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (userData?.permission_level === "Fmtv") {
                navigate("/campaign-edit", { state: { campaign: item.id } });
              } else {
                setCampaigns(item);
                if (
                  userData?.permission_level === "Admin" ||
                  userData?.permission_level === "Superuser" ||
                  userData?.permission_level === "Publisher" ||
                  userData?.permission_level === "Creator"
                ) {
                  setDrawerOpen({ ...drawerOpen, CampaignDetailDrawer: true, editCampaignName: item.id });
                }
              }
            }}>
            {item.campaign_name}
          </span>
          {item.screen_type === "In-Store" && (
            <Tooltip title="View Promo Sequence">
              <SwapOutlined
                style={{ marginLeft: 8, fontSize: 13, color: "#000", transform: "rotate(90deg)" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSequenceClick(item);
                }}
              />
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: "Description",
      dataIndex: "current_description",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("current_description")
    },
    {
      title: "Screen Type",
      dataIndex: "screen_type"
    },
    {
      title: "Orientation",
      dataIndex: "orientation",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("orientation"),
      render: (orientation) => orientation || "Landscape"
    }
  ];

  return (
    <TableWrapper>
      <CampaignsTable>
        <Table
          dataSource={campaignsData.data}
          columns={columns}
          loading={campaignsData.loading}
          pagination={{ showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100"], defaultPageSize: 10 }}
          onChange={(a, b, c) => {
            let order = "";
            if (c.order === "ascend") order = "asc";
            else if (c.order) order = "desc";
            if (c.field) {
              dispatch(SortCampaign(c.field, order, setCampaignsData, campaignsData, selectedCompany));
            }
          }}
        />
        {!createCampaignDrawer && (
          <div
            className="new-promotion"
            onClick={() => {
              if (userData?.permission_level === "Admin" || userData?.permission_level === "Superuser") {
                setCreateCampaignDrawer(true);
              } else {
                navigate("/campaign-setup");
              }
            }}>
            <NewPromotionIcon />
          </div>
        )}
      </CampaignsTable>

      <DrawerComponent placement="right" setDrawerOpen={setCreateCampaignDrawer} drawerOpen={createCampaignDrawer}>
        <AdminCreateCampaign
          setDrawerOpen={setCreateCampaignDrawer}
          drawerOpen={createCampaignDrawer}
          campaign={campaigns}
          campaignsData={campaignsData}
          setCampaignsData={setCampaignsData}
        />
      </DrawerComponent>

      <DrawerComponent placement="right" setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen.CampaignDetailDrawer}>
        <CampaignDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} campaign={campaigns} />
      </DrawerComponent>
      <Drawer
        title="Campaign Promotion Sequence"
        visible={sequenceDrawerOpen}
        onClose={handleCancelSequence}
        width={400}>
        {sequenceData.loading ? (
          <Spin />
        ) : (
          <>
            {sequenceData.data && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="promoSequence">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {sequenceData.data.widgets?.map((widget, index) => (
                        <Draggable
                          key={widget.widgetId.toString()}
                          draggableId={widget.widgetId.toString()}
                          index={index}>
                          {(providedInner) => (
                            <div
                              ref={providedInner.innerRef}
                              {...providedInner.draggableProps}
                              {...providedInner.dragHandleProps}
                              style={{
                                userSelect: "none",
                                marginBottom: 10,
                                padding: 8,
                                background: "#f4f4f4",
                                ...providedInner.draggableProps.style
                              }}>
                              <div>
                                <strong>Media ID:</strong> {widget.mediaId}
                              </div>
                              <div>
                                <strong>Promotion Name:</strong> {widget.promotion}
                              </div>
                              <div>
                                <strong>Display Order:</strong> {widget.displayOrder}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleCancelSequence}>Cancel</Button>
              <Button type="primary" onClick={handleSubmitSequence}>
                Submit
              </Button>
            </div>
          </>
        )}
      </Drawer>
    </TableWrapper>
  );
};

export default Campaigns;
